import React from 'react'

import Header from './Header/index.jsx'
import HeroImage from './HeroImage/index.jsx'
import ImageGrid from './ImageGrid/index.jsx'
import Location from './Location/index.jsx'

const Index = () => {  
  return (
    <main>
      <HeroImage />
      <Header />
      <ImageGrid />
      <Location />
    </main>
  )
}

export default Index
