import React from 'react'

import Button from 'components/Button'
import ContentHolder from 'components/ContentHolder'

import * as styles from './styles.module.scss'

const Header = () => {
  return (
    <header className={styles.root}>
      <ContentHolder>
        <div className={styles.content}>
          <h3 style={{ textTransform: 'uppercase' }}>Open 7 days a week</h3>
          <h1>Casual Dining with an Ocean Breeze</h1>
          <h3>Open Breakfast & Lunch</h3>

          <p className={styles.description}>
            Ocean facing cafe located on Ocean Street, Narrabeen with sophisticated urban look serving Toby Estate coffee, breakfast and lunch. Milkshakes, Smoothies and Fruit Frappes. House baked Muffins and Cakes. Surrounded by plenty of on street car parking. Order ahead by Phone or HeyYou App makes it easy to grab and go.
          </p>

          <Button href="tel:0299137491" style={{ marginTop: 32 }}>
            Call Ahead 9913 7491
          </Button>
        </div>
      </ContentHolder>
    </header>
  )
}

export default Header
