import React from 'react'

import ContentHolder from 'components/ContentHolder'

import * as styles from './styles.module.scss'

const Location = () => {
  return (
    <header className={styles.root}>
      <ContentHolder>
        <div className={styles.content}>
          <h3 style={{ textTransform: 'uppercase' }}>Where to Find Us</h3>
          <h1>Location</h1>
          <h3>3/18 Ocean St, Narrabeen</h3>
          <h3 style={{ marginTop: 16 }}>02 9913 7491</h3>

          <div className={styles.map}>
            <iframe
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              allowfullscreen
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_API_KEY}&q=Black+Honey+Narrabeen,Narrabeen+NSW`}
            />
          </div>
        </div>
      </ContentHolder>
    </header>
  )
}

export default Location
