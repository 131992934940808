import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

import ContentHolder from 'components/ContentHolder'

import * as styles from './styles.module.scss'

const ImageGrid = (props) => {
  const { prop } = props

  return (
    <div className={styles.root}>
      <ContentHolder>
          <div className={styles.grid}>
            <div className={classnames(styles.item, styles.doubleHeight)}>
              <StaticImage
                className={styles.imageWrapper}
                src="./assets/barista.jpg"
                alt="Barista"
                objectPosition="65% 50%"
                placeholder="blurred"
                layout="fullWidth"
                transformOptions={{ cropFocus: 'attention' }}
              />
            </div>

            <div className={styles.item}>
              <StaticImage
                className={styles.imageWrapper}
                src="./assets/croissants-cropped.jpg"
                alt="Croissants"
                objectPosition="50% 90%"
                placeholder="blurred"
                layout="fullWidth"
                transformOptions={{ cropFocus: 'attention' }}
              />
            </div>

            <div className={styles.item}>
              <StaticImage
                className={styles.imageWrapper}
                src="./assets/bacon-egg-roll.jpg"
                alt="Bacon and Egg Roll"
                placeholder="blurred"
                layout="fullWidth"
                transformOptions={{ cropFocus: 'attention' }}
              />
            </div>
          </div>
      </ContentHolder>
    </div>
  )
}

ImageGrid.propTypes = {}

export default ImageGrid
